import './Styles.scss';

const selectors = {
    'searchOpen': 'search--open'
}

$(function() {
    $('.search').each(function() {
        const searchForm = $(this)
        const searchInput = $('.search__input', searchForm)
        const closeButton = $('.search__close', searchForm)

        const openSearch = () => {
            $('body').addClass('modal-open')
            searchForm.addClass(selectors.searchOpen)
        }

        const closeSearch = () => {
            $('body').removeClass('modal-open')
            searchForm.removeClass(selectors.searchOpen)
        }

        // Event listeners
        searchInput.on('focus', function(event) {
            openSearch()
        })

        searchInput.on('click', function(event) {
            openSearch()
        })

        closeButton.on('click', function(event) {
            closeSearch()
        })

        $(document).on('click', '.search--open .modal-backdrop', function(event) {
            closeSearch()
        })

        $(document).keyup(function(event) {
            if (event.key === 'Escape' && searchForm.hasClass(selectors.searchOpen)) {
                closeSearch()
            }
        })
    })
})