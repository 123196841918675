import './Datepicker.scss';

import 'tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4';

$(function () {
    $('.datetimepicker-input').datetimepicker({
        locale: 'cs',
        format: 'L'
    })

    $(document).on('click', '.datetimepicker-input ~ .input-group-text', function(event) {
        event.preventDefault()

        const inputGroup = $(this).closest('.input-group')
        $('.datetimepicker-input', inputGroup).trigger('click')
    })
})