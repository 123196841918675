import './components/Common'
import './components/FontAwesome'
import './components/Sidebar'
import './components/Header'

// Forms
import './components/Forms/Forms'

import './components/Datagrid'
import { init as initPopover } from './components/Popover/Popover'
import './components/Help/Help'

export default {
    initPopover
}