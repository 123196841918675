import './Popover.scss'
import Popover from 'bootstrap/js/src/popover'

function init() {
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new Popover(popoverTriggerEl)
    })
}

init()

export {
    init
}