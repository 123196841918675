import './Styles.scss';

// Sidebar collapse
$(function() {
    $(document).on('click', '.sidebar__toggler', function(e) {
        e.preventDefault()

        $('body').toggleClass('sidebar-collapsed')
    })
})

// Submenu collapse
$(function() {
    $(document).on('click', '.sidebar-nav__item--has-submenu > .sidebar-nav__link', function(e) {
        e.preventDefault()

        const link = $(this)
        const parentItem = link.closest('.sidebar-nav__item')

        parentItem.toggleClass('sidebar-nav__item--open')
    })
})

// Mobile menu
$(function() {
   $(document).on('click', '.sidebar__nav-toggler', function(e) {
       e.preventDefault()

       $('body').toggleClass('has-open-menu')
   })

    // Clone HTML
    $('#placeholder-quick-action-dropdown').replaceWith($('#quick-action-dropdown').clone())
    $('#placeholder-create-action-dropdown').replaceWith($('#create-action-dropdown').clone())
})