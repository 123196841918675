import './Select.scss';

$(function () {
    $('.form-select').each(function() {
       const select = $(this)

        select.select2({
            minimumResultsForSearch: (select.data('minimumResultsForSearch')) ? select.data('minimumResultsForSearch') : Infinity,
            placeholder:             (select.data('placeholder')) ? select.data('placeholder') : false
        })

        select.on('select2:open', () => {
            setTimeout(() => {
                document.querySelector('.select2-search__field').focus()
            }, 20)
        });
    })
})